import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
// import loading from 'images/icons/three-dots.svg'

class SearchMap extends Component {
  // constructor(props) {
  //   super(props)
  // }

  link(voivodeship) {
    const id = {
      "dolnośląskie": 22,
      "kujawsko-pomorskie": 23,
      "lubelskie": 24,
      "lubuskie": 25,
      "łódzkie": 26,
      "małopolskie": 27,
      "mazowieckie": 28,
      "opolskie": 29,
      "podkarpackie": 30,
      "podlaskie": 31,
      "pomorskie": 32,
      "śląskie": 33,
      "świętokrzyskie": 34,
      "warmińsko-mazurskie": 35,
      "wielkopolskie": 36,
      "zachodniopomorskie": 37,
    }[voivodeship]
    return `${window.location.pathname !== '/' ? window.location.pathname : ''}/videos/by_voivodeship?q=%5B%5B%5D%2C%7B"5"%3A"${id}"%7D%5D`
  }

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 661.22 613.01" width="90%">
        {/* <!-- pomorskie --> */}
        <a className="svg-voivodeship" href={this.link("pomorskie")}>
          <polygon points="209.84 145.92 193.72 145.92 177.6 129.81 193.72 113.71 177.6 97.61 177.6 65.41 193.72 49.31 177.6 33.2 193.72 33.2 209.84 17.1 225.99 17.2 242.08 1 274.31 1 306.55 33.2 298.49 41.26 290.43 33.2 290.43 49.31 306.55 65.41 338.79 65.41 338.79 97.61 354.9 113.71 338.79 129.82 306.55 129.81 290.43 113.71 258.19 113.71 242.08 129.81 225.96 129.82 209.84 145.92" style={{fill: '#95d3e1'}} />
          <polygon points="209.84 145.92 193.72 145.92 177.6 129.81 193.72 113.71 177.6 97.61 177.6 65.41 193.72 49.31 177.6 33.2 193.72 33.2 209.84 17.1 225.99 17.2 242.08 1 274.31 1 306.55 33.2 298.49 41.26 290.43 33.2 290.43 49.31 306.55 65.41 338.79 65.41 338.79 97.61 354.9 113.71 338.79 129.82 306.55 129.81 290.43 113.71 258.19 113.71 242.08 129.81 225.96 129.82 209.84 145.92" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M232.21,83.77a3.68,3.68,0,0,1-3.45,3.88,2.69,2.69,0,0,1-2.22-1v3.09a.23.23,0,0,1-.26.25H224.5a.23.23,0,0,1-.26-.25V80.36a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v.56a2.65,2.65,0,0,1,2.22-1A3.69,3.69,0,0,1,232.21,83.77Zm-2.33,0a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,229.88,83.77Z" style={{fill: '#008ac4'}} />
            <path d="M233,83.77a4,4,0,1,1,4,3.88A3.9,3.9,0,0,1,233,83.77Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M253.79,82.7v4.46a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25V83c0-.84-.39-1.2-1-1.2s-1.26.46-1.28,1.54v3.86a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25V83c0-.84-.39-1.2-1.07-1.2s-1.14.39-1.23,1.29v4.11a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25V81a2.32,2.32,0,0,1,2.1-1.14,2.27,2.27,0,0,1,2.21,1.3,2.61,2.61,0,0,1,2.4-1.3C252.56,79.87,253.79,80.56,253.79,82.7Z" style={{fill: '#008ac4'}} />
            <path d="M255,83.77a4,4,0,1,1,4,3.88A3.89,3.89,0,0,1,255,83.77Zm5.68,0a1.67,1.67,0,1,0-3.34,0,1.67,1.67,0,1,0,3.34,0Z" style={{fill: '#008ac4'}} />
            <path d="M268.93,80a.23.23,0,0,1,.16.32l-.43,1.59c-.05.18-.17.21-.32.12a1.37,1.37,0,0,0-.61-.15c-.68,0-1.11.49-1.11,1.44v3.84a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25V81a1.8,1.8,0,0,1,1.56-1.17A2,2,0,0,1,268.93,80Z" style={{fill: '#008ac4'}} />
            <path d="M269.41,86.63a.25.25,0,0,1-.05-.36l.83-1.17c.1-.15.22-.15.34,0a2.76,2.76,0,0,0,2.07.84c.65,0,1.13-.18,1.13-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23.94-2.32,2.85-2.32a4.58,4.58,0,0,1,3,.94.25.25,0,0,1,0,.36l-.83,1.07c-.1.15-.22.15-.34,0a2.53,2.53,0,0,0-1.79-.67c-.55,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44s-1.5,2.46-3.15,2.46A4.54,4.54,0,0,1,269.41,86.63Z" style={{fill: '#008ac4'}} />
            <path d="M281.81,80.11H284c.21,0,.28.15.15.31l-2.61,3L284,87.1c.12.16.05.31-.16.31h-2.16a.34.34,0,0,1-.3-.16l-2.13-3.33v3.24a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25V76.72a.23.23,0,0,1,.26-.26H279a.23.23,0,0,1,.26.26v6.6l2.22-3.06A.34.34,0,0,1,281.81,80.11Z" style={{fill: '#008ac4'}} />
            <path d="M285,77.81a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,285,77.81Zm2.25,9.6h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8A.23.23,0,0,1,287.24,87.41Z" style={{fill: '#008ac4'}} />
            <path d="M296.41,83.93a.25.25,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.45,2.45,0,0,0,1.79-.68.22.22,0,0,1,.36,0l1.06,1.2a.23.23,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.79,3.79,0,0,1-3.9-3.84,3.85,3.85,0,0,1,3.88-3.94A3.75,3.75,0,0,1,296.41,83.93Zm-5.21-.87h2.85A1.48,1.48,0,0,0,292.64,82,1.5,1.5,0,0,0,291.2,83.06Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- zachodniopomorskie --> */}
        <a className="svg-voivodeship" href={this.link("zachodniopomorskie")}>
          <polygon points="161.99 33.15 178.09 33.15 194.19 49.24 178.09 65.34 178.09 97.54 194.19 113.64 178.09 129.73 178.09 145.83 161.99 145.83 178.09 161.93 145.89 194.13 129.79 194.13 113.69 178.03 113.69 194.13 97.59 194.13 81.5 210.22 49.3 210.22 25.15 234.37 1 210.22 1 194.13 33.2 161.93 17.1 145.83 17.1 97.54 33.2 97.54 49.3 81.44 81.5 81.44 97.59 65.34 129.79 65.34 161.99 33.15" style={{fill: '#95d3e1'}} />
          <polygon points="161.99 33.15 178.09 33.15 194.19 49.24 178.09 65.34 178.09 97.54 194.19 113.64 178.09 129.73 178.09 145.83 161.99 145.83 178.09 161.93 145.89 194.13 129.79 194.13 113.69 178.03 113.69 194.13 97.59 194.13 81.5 210.22 49.3 210.22 25.15 234.37 1 210.22 1 194.13 33.2 161.93 17.1 145.83 17.1 97.54 33.2 97.54 49.3 81.44 81.5 81.44 97.59 65.34 129.79 65.34 161.99 33.15" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M34,119.08l-3,3.28h3.24a.23.23,0,0,1,.25.26v1.5a.23.23,0,0,1-.25.25h-6a.23.23,0,0,1-.25-.25v-1.35a.46.46,0,0,1,.12-.32l3.1-3.37H28a.23.23,0,0,1-.26-.26v-1.5a.23.23,0,0,1,.26-.25h5.82a.23.23,0,0,1,.25.25v1.44A.46.46,0,0,1,34,119.08Z" style={{fill: '#008ac4'}} />
            <path d="M40.84,117.32a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.8a.23.23,0,0,1-.25.25H41.09a.23.23,0,0,1-.25-.25v-.54a2.7,2.7,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.67,2.67,0,0,1,2.22,1Zm0,3.41a1.68,1.68,0,1,0-1.67,1.8A1.67,1.67,0,0,0,40.84,120.73Z" style={{fill: '#008ac4'}} />
            <path d="M44.45,120.71a3.79,3.79,0,0,1,3.92-3.88A3.85,3.85,0,0,1,51.28,118a.25.25,0,0,1,0,.36l-1.14,1.19a.22.22,0,0,1-.36,0,1.68,1.68,0,0,0-3,1.15,1.69,1.69,0,0,0,1.63,1.82,1.66,1.66,0,0,0,1.37-.65.22.22,0,0,1,.36,0L51.28,123a.25.25,0,0,1,0,.36,3.83,3.83,0,0,1-2.91,1.23A3.79,3.79,0,0,1,44.45,120.71Z" style={{fill: '#008ac4'}} />
            <path d="M59.48,119.66v4.46a.23.23,0,0,1-.25.25H57.44a.23.23,0,0,1-.25-.25v-4c0-1-.39-1.41-1.13-1.41s-1.36.46-1.38,1.54v3.86a.23.23,0,0,1-.25.25H52.64a.23.23,0,0,1-.25-.25V113.68a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26V118A2.54,2.54,0,0,1,57,116.83C58.25,116.83,59.48,117.52,59.48,119.66Z" style={{fill: '#008ac4'}} />
            <path d="M60.68,120.73a4,4,0,1,1,4,3.88A3.9,3.9,0,0,1,60.68,120.73Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M75.17,113.68a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v10.44a.23.23,0,0,1-.26.25H75.43a.23.23,0,0,1-.26-.25v-.54a2.69,2.69,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.65,2.65,0,0,1,2.22,1Zm0,7a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,75.17,120.73Z" style={{fill: '#008ac4'}} />
            <path d="M86.32,119.66v4.46a.23.23,0,0,1-.26.25H84.28a.23.23,0,0,1-.26-.25v-4c0-1-.39-1.41-1.12-1.41a1.32,1.32,0,0,0-1.37,1.3v4.1a.23.23,0,0,1-.25.25H79.49a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25V118a2.51,2.51,0,0,1,2.27-1.2C85.09,116.83,86.32,117.52,86.32,119.66Z" style={{fill: '#008ac4'}} />
            <path d="M87.83,114.77a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,87.83,114.77Zm2.25,9.6H88.3a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8A.23.23,0,0,1,90.08,124.37Z" style={{fill: '#008ac4'}} />
            <path d="M91.58,120.73a4,4,0,1,1,4,3.88A3.9,3.9,0,0,1,91.58,120.73Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M108.8,120.73a3.68,3.68,0,0,1-3.45,3.88,2.7,2.7,0,0,1-2.22-1v3.09a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-9.35a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v.56a2.67,2.67,0,0,1,2.22-1A3.69,3.69,0,0,1,108.8,120.73Zm-2.32,0a1.68,1.68,0,1,0-1.67,1.8A1.67,1.67,0,0,0,106.48,120.73Z" style={{fill: '#008ac4'}} />
            <path d="M109.6,120.73a4,4,0,1,1,4,3.88A3.89,3.89,0,0,1,109.6,120.73Zm5.68,0a1.67,1.67,0,1,0-3.34,0,1.67,1.67,0,1,0,3.34,0Z" style={{fill: '#008ac4'}} />
            <path d="M130.39,119.66v4.46a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-4.2c0-.84-.39-1.2-1-1.2s-1.26.46-1.27,1.54v3.86a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-4.2c0-.84-.39-1.2-1.06-1.2s-1.14.39-1.23,1.29v4.11a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25H121a.23.23,0,0,1,.26.25V118a2.31,2.31,0,0,1,2.1-1.14,2.26,2.26,0,0,1,2.2,1.3,2.61,2.61,0,0,1,2.4-1.3C129.16,116.83,130.39,117.52,130.39,119.66Z" style={{fill: '#008ac4'}} />
            <path d="M131.6,120.73a4,4,0,1,1,4,3.88A3.9,3.9,0,0,1,131.6,120.73Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M145.52,117a.24.24,0,0,1,.17.32l-.44,1.59c0,.18-.16.21-.31.12a1.38,1.38,0,0,0-.62-.15c-.67,0-1.11.49-1.11,1.44v3.84a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25H143a.23.23,0,0,1,.25.25V118a1.82,1.82,0,0,1,1.56-1.17A2,2,0,0,1,145.52,117Z" style={{fill: '#008ac4'}} />
            <path d="M146,123.59a.25.25,0,0,1,0-.36l.82-1.17c.11-.15.23-.15.35,0a2.76,2.76,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23.95-2.32,2.85-2.32a4.56,4.56,0,0,1,3,.94.25.25,0,0,1,0,.36l-.82,1.07c-.11.15-.23.15-.35,0a2.5,2.5,0,0,0-1.78-.67c-.56,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44,0,1.71-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,146,123.59Z" style={{fill: '#008ac4'}} />
            <path d="M158.41,117.07h2.2c.21,0,.29.15.15.31l-2.61,3,2.48,3.69c.12.16,0,.31-.17.31H158.3a.37.37,0,0,1-.3-.16l-2.13-3.33v3.24a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25V113.68a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v6.6l2.22-3.06A.36.36,0,0,1,158.41,117.07Z" style={{fill: '#008ac4'}} />
            <path d="M161.59,114.77a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,161.59,114.77Zm2.25,9.6h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.8A.23.23,0,0,1,163.84,124.37Z" style={{fill: '#008ac4'}} />
            <path d="M173,120.89a.25.25,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.45,2.45,0,0,0,1.79-.68.22.22,0,0,1,.36,0l1.06,1.2a.23.23,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.79,3.79,0,0,1-3.9-3.84,3.85,3.85,0,0,1,3.88-3.94A3.75,3.75,0,0,1,173,120.89Zm-5.21-.87h2.85a1.48,1.48,0,0,0-1.41-1.09A1.5,1.5,0,0,0,167.78,120Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- warmińsko-mazurskie --> */}
        <a className="svg-voivodeship" href={this.link("warmińsko-mazurskie")}>
          <polygon points="338.51 161.96 338.51 129.75 354.62 113.64 338.51 97.53 338.51 65.32 354.62 49.22 547.91 49.22 531.8 65.32 547.91 81.43 547.91 113.64 515.69 145.85 467.37 145.85 451.26 161.96 419.05 161.96 402.94 178.06 354.62 178.06 338.51 161.96" style={{fill: '#95d3e1'}} />
          <polygon points="338.51 161.96 338.51 129.75 354.62 113.64 338.51 97.53 338.51 65.32 354.62 49.22 547.91 49.22 531.8 65.32 547.91 81.43 547.91 113.64 515.69 145.85 467.37 145.85 451.26 161.96 419.05 161.96 402.94 178.06 354.62 178.06 338.51 161.96" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M385.66,105.79a.28.28,0,0,1,.3-.21h1.87a.21.21,0,0,1,.21.3l-2.55,6.79a.28.28,0,0,1-.28.21h-2a.28.28,0,0,1-.29-.19l-1.39-3.57-1.4,3.57a.27.27,0,0,1-.28.19h-2a.28.28,0,0,1-.28-.21L375,105.88a.21.21,0,0,1,.21-.3h1.88a.26.26,0,0,1,.28.21l1.49,4.62,1.54-4.62a.28.28,0,0,1,.3-.21h1.68a.28.28,0,0,1,.3.21l1.55,4.56Z" style={{fill: '#008ac4'}} />
            <path d="M394,105.83a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-.54a2.69,2.69,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.65,2.65,0,0,1,2.22,1Zm0,3.41a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,394,109.24Z" style={{fill: '#008ac4'}} />
            <path d="M402.73,105.47a.23.23,0,0,1,.16.32l-.43,1.59c0,.18-.17.21-.32.12a1.37,1.37,0,0,0-.61-.15c-.68,0-1.11.49-1.11,1.44v3.84a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v.68a1.8,1.8,0,0,1,1.56-1.17A2,2,0,0,1,402.73,105.47Z" style={{fill: '#008ac4'}} />
            <path d="M415.24,108.17v4.46a.23.23,0,0,1-.26.25H413.2a.23.23,0,0,1-.26-.25v-4.2c0-.84-.39-1.2-1-1.2s-1.26.46-1.27,1.54v3.86a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-4.2c0-.84-.39-1.2-1.06-1.2s-1.14.39-1.23,1.29v4.11a.23.23,0,0,1-.26.25H404a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v.65a2.31,2.31,0,0,1,2.1-1.14,2.26,2.26,0,0,1,2.2,1.3,2.61,2.61,0,0,1,2.4-1.3C414,105.34,415.24,106,415.24,108.17Z" style={{fill: '#008ac4'}} />
            <path d="M416.77,103.28a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,416.77,103.28Zm2.25,9.6h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25H419a.23.23,0,0,1,.25.25v6.8A.23.23,0,0,1,419,112.88Z" style={{fill: '#008ac4'}} />
            <path d="M428.26,108.17v4.46a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-4c0-1-.39-1.41-1.12-1.41a1.32,1.32,0,0,0-1.37,1.3v4.1a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v.71a2.51,2.51,0,0,1,2.27-1.2C427,105.34,428.26,106,428.26,108.17Zm-3.15-6.49h1.78c.21,0,.29.15.17.31l-1.67,2.28a.34.34,0,0,1-.31.15h-1.34c-.19,0-.27-.12-.18-.3l1.23-2.26A.34.34,0,0,1,425.11,101.68Z" style={{fill: '#008ac4'}} />
            <path d="M429.56,112.1a.25.25,0,0,1,0-.36l.82-1.17c.11-.15.23-.15.35,0a2.76,2.76,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23.95-2.32,2.85-2.32a4.56,4.56,0,0,1,3,.94.25.25,0,0,1,0,.36l-.82,1.07c-.11.15-.23.15-.35,0a2.5,2.5,0,0,0-1.78-.67c-.56,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44s-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,429.56,112.1Z" style={{fill: '#008ac4'}} />
            <path d="M442,105.58h2.2c.21,0,.29.15.15.31l-2.61,3,2.48,3.69c.12.16,0,.31-.17.31h-2.16a.37.37,0,0,1-.3-.16l-2.13-3.33v3.24a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25V102.19a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v6.6l2.22-3.06A.36.36,0,0,1,442,105.58Z" style={{fill: '#008ac4'}} />
            <path d="M444.59,109.24a4,4,0,1,1,4,3.88A3.9,3.9,0,0,1,444.59,109.24Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M460,107.3v1.62a.23.23,0,0,1-.25.26h-5.51a.23.23,0,0,1-.25-.26V107.3a.23.23,0,0,1,.25-.25h5.51A.23.23,0,0,1,460,107.3Z" style={{fill: '#008ac4'}} />
            <path d="M473.18,108.17v4.46a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-4.2c0-.84-.39-1.2-1-1.2s-1.26.46-1.28,1.54v3.86a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-4.2c0-.84-.39-1.2-1.07-1.2s-1.14.39-1.23,1.29v4.11a.23.23,0,0,1-.25.25H462a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v.65a2.32,2.32,0,0,1,2.1-1.14,2.27,2.27,0,0,1,2.21,1.3,2.61,2.61,0,0,1,2.4-1.3C472,105.34,473.18,106,473.18,108.17Z" style={{fill: '#008ac4'}} />
            <path d="M480.08,105.83a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-.54a2.69,2.69,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.65,2.65,0,0,1,2.22,1Zm0,3.41a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,480.08,109.24Z" style={{fill: '#008ac4'}} />
            <path d="M490,107.59l-3,3.28h3.24a.23.23,0,0,1,.25.26v1.5a.23.23,0,0,1-.25.25h-6a.23.23,0,0,1-.25-.25v-1.35a.46.46,0,0,1,.12-.32l3.1-3.37h-3.16a.23.23,0,0,1-.26-.26v-1.5a.23.23,0,0,1,.26-.25h5.82a.23.23,0,0,1,.25.25v1.44A.46.46,0,0,1,490,107.59Z" style={{fill: '#008ac4'}} />
            <path d="M496.37,105.83a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-.71a2.45,2.45,0,0,1-2.22,1.2c-1.27,0-2.46-.69-2.46-2.82v-4.47a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v4c0,1.05.36,1.41,1.05,1.41s1.32-.46,1.33-1.54Z" style={{fill: '#008ac4'}} />
            <path d="M505.07,105.47a.24.24,0,0,1,.17.32l-.44,1.59c0,.18-.16.21-.31.12a1.38,1.38,0,0,0-.62-.15c-.67,0-1.11.49-1.11,1.44v3.84a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v.68a1.82,1.82,0,0,1,1.56-1.17A2,2,0,0,1,505.07,105.47Z" style={{fill: '#008ac4'}} />
            <path d="M505.54,112.1a.25.25,0,0,1-.05-.36l.83-1.17c.1-.15.22-.15.34,0a2.76,2.76,0,0,0,2.07.84c.65,0,1.13-.18,1.13-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23.94-2.32,2.85-2.32a4.58,4.58,0,0,1,3,.94.25.25,0,0,1,0,.36l-.83,1.07c-.1.15-.22.15-.34,0a2.53,2.53,0,0,0-1.79-.67c-.55,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44s-1.5,2.46-3.15,2.46A4.54,4.54,0,0,1,505.54,112.1Z" style={{fill: '#008ac4'}} />
            <path d="M517.94,105.58h2.21c.21,0,.28.15.15.31l-2.61,3,2.47,3.69c.12.16.05.31-.16.31h-2.16a.34.34,0,0,1-.3-.16l-2.13-3.33v3.24a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25V102.19a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v6.6l2.22-3.06A.34.34,0,0,1,517.94,105.58Z" style={{fill: '#008ac4'}} />
            <path d="M521.12,103.28a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,521.12,103.28Zm2.25,9.6h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8A.23.23,0,0,1,523.37,112.88Z" style={{fill: '#008ac4'}} />
            <path d="M532.54,109.4a.25.25,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.45,2.45,0,0,0,1.79-.68.22.22,0,0,1,.36,0l1.06,1.2a.23.23,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.79,3.79,0,0,1-3.9-3.84,3.85,3.85,0,0,1,3.88-3.94A3.75,3.75,0,0,1,532.54,109.4Zm-5.21-.87h2.85a1.48,1.48,0,0,0-1.41-1.09A1.5,1.5,0,0,0,527.33,108.53Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- lubuskie --> */}
        <a className="svg-voivodeship" href={this.link("lubuskie")}>
          <polygon points="33.11 242.11 25.05 234.05 49.24 209.86 81.48 209.86 97.6 193.73 113.73 193.73 113.73 177.6 129.85 193.73 129.85 225.98 113.73 225.98 113.73 290.49 145.97 322.74 137.91 330.8 129.85 322.74 97.6 354.99 89.54 346.93 73.42 363.05 65.36 354.99 49.24 371.12 49.24 354.99 33.11 338.87 33.11 306.61 49.24 290.49 33.11 274.36 33.11 242.11" style={{fill: '#95d3e1'}} />
          <polygon points="33.11 242.11 25.05 234.05 49.24 209.86 81.48 209.86 97.6 193.73 113.73 193.73 113.73 177.6 129.85 193.73 129.85 225.98 113.73 225.98 113.73 290.49 145.97 322.74 137.91 330.8 129.85 322.74 97.6 354.99 89.54 346.93 73.42 363.05 65.36 354.99 49.24 371.12 49.24 354.99 33.11 338.87 33.11 306.61 49.24 290.49 33.11 274.36 33.11 242.11" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M47.54,271V260.56a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26V271a.23.23,0,0,1-.26.25H47.8A.23.23,0,0,1,47.54,271Z" style={{fill: '#008ac4'}} />
            <path d="M56.18,264.2a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25V271a.23.23,0,0,1-.26.25H56.44a.23.23,0,0,1-.26-.25v-.71a2.45,2.45,0,0,1-2.22,1.2c-1.27,0-2.46-.69-2.46-2.82V264.2a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v4c0,1.05.36,1.41,1.05,1.41s1.32-.46,1.33-1.54Z" style={{fill: '#008ac4'}} />
            <path d="M68.17,267.61a3.68,3.68,0,0,1-3.45,3.88,2.69,2.69,0,0,1-2.22-1V271a.23.23,0,0,1-.26.25H60.46a.23.23,0,0,1-.26-.25V260.56a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v4.2a2.65,2.65,0,0,1,2.22-1.05A3.69,3.69,0,0,1,68.17,267.61Zm-2.33,0a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,65.84,267.61Z" style={{fill: '#008ac4'}} />
            <path d="M74.08,264.2a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25V271a.23.23,0,0,1-.25.25H74.33a.23.23,0,0,1-.25-.25v-.71a2.47,2.47,0,0,1-2.22,1.2c-1.28,0-2.46-.69-2.46-2.82V264.2a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v4c0,1.05.36,1.41,1,1.41s1.32-.46,1.34-1.54Z" style={{fill: '#008ac4'}} />
            <path d="M77.69,270.47a.25.25,0,0,1,0-.36l.82-1.17c.11-.15.23-.15.35,0a2.76,2.76,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23,1-2.32,2.85-2.32a4.56,4.56,0,0,1,3,.94.25.25,0,0,1,0,.36l-.82,1.07c-.11.15-.23.15-.35,0a2.5,2.5,0,0,0-1.78-.67c-.56,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44s-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,77.69,270.47Z" style={{fill: '#008ac4'}} />
            <path d="M90.1,264h2.2c.21,0,.29.15.15.31l-2.61,3,2.48,3.69c.12.16,0,.31-.17.31H90a.37.37,0,0,1-.3-.16l-2.13-3.33V271a.23.23,0,0,1-.25.25H85.52a.23.23,0,0,1-.25-.25V260.56a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v6.6l2.22-3.06A.36.36,0,0,1,90.1,264Z" style={{fill: '#008ac4'}} />
            <path d="M93.28,261.65A1.35,1.35,0,1,1,94.63,263,1.32,1.32,0,0,1,93.28,261.65Zm2.25,9.6H93.74a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25V271A.23.23,0,0,1,95.53,271.25Z" style={{fill: '#008ac4'}} />
            <path d="M104.69,267.77a.24.24,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.43,2.43,0,0,0,1.78-.68.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.86,3.86,0,0,1,3.89-3.94A3.74,3.74,0,0,1,104.69,267.77Zm-5.2-.87h2.85a1.48,1.48,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- wielkopolskie --> */}
        <a className="svg-voivodeship" href={this.link("wielkopolskie")}>
          <polygon points="145.7 322.58 113.5 290.38 113.5 225.98 129.6 225.98 129.6 193.78 145.7 193.78 177.89 161.59 161.79 145.49 177.89 145.49 177.89 129.39 193.99 145.49 210.09 145.49 210.09 161.59 218.13 169.64 210.09 177.69 210.09 193.78 226.18 209.88 226.18 225.98 237.48 244.09 250.33 234.03 274.47 258.18 282.52 250.13 298.62 266.23 306.67 258.18 322.76 274.28 306.67 290.38 306.67 306.48 290.57 322.58 274.47 322.58 274.47 354.77 258.38 370.88 258.38 403.07 242.28 403.07 226.18 386.97 226.18 370.88 218.13 362.82 226.18 354.78 210.09 338.68 193.99 354.78 177.89 354.77 145.7 322.58" style={{fill: '#95d3e1'}} />
          <polygon points="145.7 322.58 113.5 290.38 113.5 225.98 129.6 225.98 129.6 193.78 145.7 193.78 177.89 161.59 161.79 145.49 177.89 145.49 177.89 129.39 193.99 145.49 210.09 145.49 210.09 161.59 218.13 169.64 210.09 177.69 210.09 193.78 226.18 209.88 226.18 225.98 237.48 244.09 250.33 234.03 274.47 258.18 282.52 250.13 298.62 266.23 306.67 258.18 322.76 274.28 306.67 290.38 306.67 306.48 290.57 322.58 274.47 322.58 274.47 354.77 258.38 370.88 258.38 403.07 242.28 403.07 226.18 386.97 226.18 370.88 218.13 362.82 226.18 354.78 210.09 338.68 193.99 354.78 177.89 354.77 145.7 322.58" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M170.48,280.31a.28.28,0,0,1,.3-.21h1.88a.21.21,0,0,1,.21.3l-2.55,6.8a.3.3,0,0,1-.29.21h-2a.27.27,0,0,1-.28-.2l-1.4-3.57-1.39,3.57a.28.28,0,0,1-.29.2h-2a.3.3,0,0,1-.29-.21l-2.53-6.8a.21.21,0,0,1,.21-.3h1.87a.29.29,0,0,1,.29.21l1.48,4.62,1.55-4.62a.28.28,0,0,1,.3-.21h1.68a.28.28,0,0,1,.3.21l1.54,4.56Z" style={{fill: '#008ac4'}} />
            <path d="M173.68,277.81a1.35,1.35,0,1,1,1.35,1.35A1.33,1.33,0,0,1,173.68,277.81Zm2.25,9.6h-1.79a.23.23,0,0,1-.25-.26v-6.79a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v6.79A.23.23,0,0,1,175.93,287.41Z" style={{fill: '#008ac4'}} />
            <path d="M185.09,283.93a.24.24,0,0,1-.27.25h-5a1.62,1.62,0,0,0,1.65,1.38,2.46,2.46,0,0,0,1.78-.67.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.84,4.84,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.87,3.87,0,0,1,3.89-3.95A3.75,3.75,0,0,1,185.09,283.93Zm-5.2-.87h2.85a1.47,1.47,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
            <path d="M186.26,287.15V276.71a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v10.44a.23.23,0,0,1-.26.26h-1.78A.23.23,0,0,1,186.26,287.15Z" style={{fill: '#008ac4'}} />
            <path d="M195.08,280.1h2.21c.21,0,.28.15.15.32l-2.61,3,2.47,3.69c.12.17,0,.32-.16.32H195a.33.33,0,0,1-.3-.17l-2.13-3.33v3.24a.23.23,0,0,1-.26.26h-1.78a.23.23,0,0,1-.26-.26V276.71a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.6l2.22-3.06A.36.36,0,0,1,195.08,280.1Z" style={{fill: '#008ac4'}} />
            <path d="M197.71,283.76a4,4,0,1,1,4,3.89A3.89,3.89,0,0,1,197.71,283.76Zm5.68,0a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,203.39,283.76Z" style={{fill: '#008ac4'}} />
            <path d="M214.93,283.76a3.68,3.68,0,0,1-3.45,3.89,2.7,2.7,0,0,1-2.22-1v3.09a.23.23,0,0,1-.26.26h-1.78a.23.23,0,0,1-.26-.26v-9.34a.23.23,0,0,1,.26-.26H209a.23.23,0,0,1,.26.26v.55a2.68,2.68,0,0,1,2.22-1.05A3.7,3.7,0,0,1,214.93,283.76Zm-2.33,0a1.67,1.67,0,1,0-1.66,1.8A1.68,1.68,0,0,0,212.6,283.76Z" style={{fill: '#008ac4'}} />
            <path d="M215.72,283.76a4,4,0,1,1,4,3.89A3.9,3.9,0,0,1,215.72,283.76Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M225,287.15V276.71a.23.23,0,0,1,.26-.25H227a.23.23,0,0,1,.26.25v10.44a.23.23,0,0,1-.26.26h-1.78A.23.23,0,0,1,225,287.15Z" style={{fill: '#008ac4'}} />
            <path d="M228.55,286.63a.25.25,0,0,1-.05-.36l.83-1.17c.1-.15.22-.15.34,0a2.72,2.72,0,0,0,2.07.84c.65,0,1.13-.18,1.13-.63s-.54-.55-1.35-.67c-1.56-.21-2.85-.77-2.85-2.43,0-1.23.94-2.33,2.85-2.33a4.59,4.59,0,0,1,3,.95.25.25,0,0,1,0,.36l-.83,1.06c-.1.15-.22.15-.34,0a2.54,2.54,0,0,0-1.79-.68c-.55,0-1,.14-1,.53s.3.52,1.32.64c1.38.2,2.88.59,2.88,2.45,0,1.71-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,228.55,286.63Z" style={{fill: '#008ac4'}} />
            <path d="M241,280.1h2.21c.21,0,.28.15.15.32l-2.61,3,2.47,3.69c.12.17.05.32-.16.32h-2.16a.33.33,0,0,1-.3-.17l-2.13-3.33v3.24a.23.23,0,0,1-.26.26h-1.78a.23.23,0,0,1-.26-.26V276.71a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.6l2.22-3.06A.36.36,0,0,1,241,280.1Z" style={{fill: '#008ac4'}} />
            <path d="M244.13,277.81a1.35,1.35,0,1,1,1.35,1.35A1.33,1.33,0,0,1,244.13,277.81Zm2.25,9.6H244.6a.23.23,0,0,1-.26-.26v-6.79a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v6.79A.23.23,0,0,1,246.38,287.41Z" style={{fill: '#008ac4'}} />
            <path d="M255.55,283.93a.25.25,0,0,1-.27.25h-5a1.62,1.62,0,0,0,1.65,1.38,2.49,2.49,0,0,0,1.79-.67.22.22,0,0,1,.36,0l1.06,1.2a.23.23,0,0,1,0,.36,4.84,4.84,0,0,1-3.3,1.2,3.79,3.79,0,0,1-3.9-3.84,3.86,3.86,0,0,1,3.88-3.95A3.76,3.76,0,0,1,255.55,283.93Zm-5.21-.87h2.85a1.48,1.48,0,0,0-1.41-1.1A1.5,1.5,0,0,0,250.34,283.06Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- kujawsko-pomorskie --> */}
        <a className="svg-voivodeship" href={this.link("kujawsko-pomorskie")}>
          <polygon points="338.75 258.45 322.65 274.55 306.55 258.45 298.49 266.5 282.39 250.39 274.34 258.45 250.19 234.29 237.34 244.35 226.04 226.24 226.04 210.13 209.94 194.03 209.94 177.92 217.99 169.87 209.94 161.82 209.94 145.72 226.04 129.61 242.14 129.61 258.24 113.5 290.44 113.51 306.55 129.61 338.75 129.61 338.75 161.82 354.85 177.92 354.85 210.13 338.75 226.24 338.75 258.45" style={{fill: '#95d3e1'}} />
          <polygon points="338.75 258.45 322.65 274.55 306.55 258.45 298.49 266.5 282.39 250.39 274.34 258.45 250.19 234.29 237.34 244.35 226.04 226.24 226.04 210.13 209.94 194.03 209.94 177.92 217.99 169.87 209.94 161.82 209.94 145.72 226.04 129.61 242.14 129.61 258.24 113.5 290.44 113.51 306.55 129.61 338.75 129.61 338.75 161.82 354.85 177.92 354.85 210.13 338.75 226.24 338.75 258.45" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M252.85,175.55h2.2c.21,0,.29.15.15.32l-2.61,3,2.48,3.69c.12.17,0,.32-.17.32h-2.16a.35.35,0,0,1-.3-.17l-2.13-3.33v3.24a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26V172.16a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.6l2.22-3.06A.38.38,0,0,1,252.85,175.55Z" style={{fill: '#008ac4'}} />
            <path d="M260.84,175.81a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v6.79a.23.23,0,0,1-.26.26H261.1a.23.23,0,0,1-.26-.26v-.7a2.45,2.45,0,0,1-2.22,1.2c-1.27,0-2.46-.69-2.46-2.82v-4.47a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v4c0,1,.36,1.41,1.05,1.41s1.32-.47,1.33-1.55Z" style={{fill: '#008ac4'}} />
            <path d="M264.76,173.36a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,264.76,173.36Zm2.25,12h-1.79a.23.23,0,0,1-.25-.26v-9.34a.23.23,0,0,1,.25-.26H267a.23.23,0,0,1,.25.26v9.34A.23.23,0,0,1,267,185.41Z" style={{fill: '#008ac4'}} />
            <path d="M274.19,175.81a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v6.79a.23.23,0,0,1-.26.26h-1.78a.23.23,0,0,1-.26-.26v-.54a2.7,2.7,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.79,2.68,2.68,0,0,1,2.22,1.05Zm0,3.4a1.67,1.67,0,1,0-1.66,1.8A1.68,1.68,0,0,0,274.19,179.21Z" style={{fill: '#008ac4'}} />
            <path d="M288.19,175.76a.28.28,0,0,1,.3-.21h1.87a.21.21,0,0,1,.21.3l-2.55,6.8a.29.29,0,0,1-.28.21h-2a.28.28,0,0,1-.29-.2L284,179.09l-1.4,3.57a.27.27,0,0,1-.28.2h-2a.29.29,0,0,1-.28-.21l-2.54-6.8a.21.21,0,0,1,.21-.3h1.88a.27.27,0,0,1,.28.21l1.49,4.62,1.54-4.62a.28.28,0,0,1,.3-.21h1.68a.28.28,0,0,1,.3.21l1.55,4.56Z" style={{fill: '#008ac4'}} />
            <path d="M291,182.08a.25.25,0,0,1-.05-.36l.83-1.17c.1-.15.22-.15.34,0a2.72,2.72,0,0,0,2.07.84c.65,0,1.13-.18,1.13-.63s-.54-.55-1.35-.67c-1.56-.21-2.85-.77-2.85-2.43,0-1.23.94-2.33,2.85-2.33a4.59,4.59,0,0,1,3,.95.25.25,0,0,1,0,.36l-.83,1.06c-.1.15-.22.15-.34,0a2.54,2.54,0,0,0-1.79-.68c-.55,0-1,.14-1,.53s.3.52,1.32.64c1.38.2,2.88.59,2.88,2.45s-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,291,182.08Z" style={{fill: '#008ac4'}} />
            <path d="M303.41,175.55h2.21c.21,0,.28.15.15.32l-2.61,3,2.47,3.69c.12.17.05.32-.16.32h-2.16a.33.33,0,0,1-.3-.17l-2.13-3.33v3.24a.23.23,0,0,1-.26.26h-1.78a.23.23,0,0,1-.26-.26V172.16a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.6l2.22-3.06A.36.36,0,0,1,303.41,175.55Z" style={{fill: '#008ac4'}} />
            <path d="M306,179.21a4,4,0,1,1,4,3.89A3.89,3.89,0,0,1,306,179.21Zm5.68,0a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,311.72,179.21Z" style={{fill: '#008ac4'}} />
            <path d="M321.43,177.28v1.62a.23.23,0,0,1-.26.25h-5.5a.23.23,0,0,1-.26-.25v-1.62a.23.23,0,0,1,.26-.26h5.5A.23.23,0,0,1,321.43,177.28Z" style={{fill: '#008ac4'}} />
            <path d="M256.78,198.22a3.68,3.68,0,0,1-3.45,3.88,2.69,2.69,0,0,1-2.22-1v3.09a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-9.35a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v.56a2.65,2.65,0,0,1,2.22-1.05A3.69,3.69,0,0,1,256.78,198.22Zm-2.33,0a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,254.45,198.22Z" style={{fill: '#008ac4'}} />
            <path d="M257.57,198.22a4,4,0,1,1,4,3.88A3.9,3.9,0,0,1,257.57,198.22Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M278.36,197.15v4.46a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-4.2c0-.84-.39-1.2-1-1.2s-1.26.46-1.28,1.54v3.86a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-4.2c0-.84-.39-1.2-1.07-1.2s-1.14.39-1.23,1.29v4.11a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v.65a2.32,2.32,0,0,1,2.1-1.14,2.27,2.27,0,0,1,2.21,1.3,2.61,2.61,0,0,1,2.4-1.3C277.13,194.32,278.36,195,278.36,197.15Z" style={{fill: '#008ac4'}} />
            <path d="M279.58,198.22a4,4,0,1,1,4,3.88A3.89,3.89,0,0,1,279.58,198.22Zm5.68,0a1.67,1.67,0,1,0-3.34,0,1.67,1.67,0,1,0,3.34,0Z" style={{fill: '#008ac4'}} />
            <path d="M293.5,194.45a.23.23,0,0,1,.16.32l-.43,1.59c-.05.18-.17.21-.32.12a1.37,1.37,0,0,0-.61-.15c-.68,0-1.11.49-1.11,1.44v3.84a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v.68a1.8,1.8,0,0,1,1.56-1.17A2,2,0,0,1,293.5,194.45Z" style={{fill: '#008ac4'}} />
            <path d="M294,201.08a.25.25,0,0,1-.05-.36l.83-1.17c.1-.15.22-.15.34,0a2.76,2.76,0,0,0,2.07.84c.65,0,1.13-.18,1.13-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23.94-2.32,2.85-2.32a4.58,4.58,0,0,1,3,.94.25.25,0,0,1,0,.36l-.83,1.07c-.1.15-.22.15-.34,0A2.53,2.53,0,0,0,297,196c-.55,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44s-1.5,2.46-3.15,2.46A4.54,4.54,0,0,1,294,201.08Z" style={{fill: '#008ac4'}} />
            <path d="M306.38,194.56h2.21c.21,0,.28.15.15.31l-2.61,3,2.47,3.69c.12.16,0,.31-.16.31h-2.16a.34.34,0,0,1-.3-.16l-2.13-3.33v3.24a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25V191.17a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v6.6l2.22-3.06A.34.34,0,0,1,306.38,194.56Z" style={{fill: '#008ac4'}} />
            <path d="M309.56,192.26a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,309.56,192.26Zm2.25,9.6H310a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8A.23.23,0,0,1,311.81,201.86Z" style={{fill: '#008ac4'}} />
            <path d="M321,198.38a.25.25,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.45,2.45,0,0,0,1.79-.68.22.22,0,0,1,.36,0l1.06,1.2a.23.23,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.79,3.79,0,0,1-3.9-3.84,3.85,3.85,0,0,1,3.88-3.94A3.75,3.75,0,0,1,321,198.38Zm-5.21-.87h2.85a1.48,1.48,0,0,0-1.41-1.09A1.5,1.5,0,0,0,315.77,197.51Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- podlaskie --> */}
        <a className="svg-voivodeship" href={this.link("podlaskie")}>
          <polygon points="483.16 177.96 483.16 145.77 515.36 145.77 547.57 113.59 547.57 81.4 531.47 65.31 547.57 49.22 563.67 49.22 579.77 65.31 595.87 65.31 595.87 113.59 611.97 129.68 611.97 161.87 628.08 177.96 628.08 226.24 611.97 226.24 579.77 258.42 547.57 258.42 531.47 242.33 531.47 210.15 515.36 210.15 483.16 177.96" style={{fill: '#95d3e1'}} />
          <polygon points="483.16 177.96 483.16 145.77 515.36 145.77 547.57 113.59 547.57 81.4 531.47 65.31 547.57 49.22 563.67 49.22 579.77 65.31 595.87 65.31 595.87 113.59 611.97 129.68 611.97 161.87 628.08 177.96 628.08 226.24 611.97 226.24 579.77 258.42 547.57 258.42 531.47 242.33 531.47 210.15 515.36 210.15 483.16 177.96" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M531.38,182.15a3.68,3.68,0,0,1-3.45,3.89,2.71,2.71,0,0,1-2.22-1v3.09a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26v-9.34a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v.55a2.69,2.69,0,0,1,2.22-1.05A3.7,3.7,0,0,1,531.38,182.15Zm-2.32,0a1.68,1.68,0,1,0-1.67,1.8A1.68,1.68,0,0,0,529.06,182.15Z" style={{fill: '#008ac4'}} />
            <path d="M532.18,182.15a4,4,0,1,1,4,3.89A3.89,3.89,0,0,1,532.18,182.15Zm5.68,0a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,537.86,182.15Z" style={{fill: '#008ac4'}} />
            <path d="M546.67,175.1a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v10.44a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26V185a2.71,2.71,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.79,2.69,2.69,0,0,1,2.22,1.05Zm0,7.05A1.68,1.68,0,1,0,545,184,1.68,1.68,0,0,0,546.67,182.15Z" style={{fill: '#008ac4'}} />
            <path d="M550.67,185.54V175.1a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v10.44a.23.23,0,0,1-.26.26h-1.78A.23.23,0,0,1,550.67,185.54Z" style={{fill: '#008ac4'}} />
            <path d="M559.9,178.75a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v6.79a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26V185a2.71,2.71,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.79,2.69,2.69,0,0,1,2.22,1.05Zm0,3.4a1.68,1.68,0,1,0-1.67,1.8A1.68,1.68,0,0,0,559.9,182.15Z" style={{fill: '#008ac4'}} />
            <path d="M563.51,185a.25.25,0,0,1,0-.36l.82-1.17a.2.2,0,0,1,.35,0,2.72,2.72,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.55-1.35-.67c-1.56-.21-2.85-.77-2.85-2.43,0-1.23,1-2.33,2.85-2.33a4.57,4.57,0,0,1,3,.95.25.25,0,0,1,0,.36l-.82,1.06a.2.2,0,0,1-.35,0,2.51,2.51,0,0,0-1.78-.68c-.56,0-1,.14-1,.53s.3.52,1.32.64c1.38.2,2.88.59,2.88,2.45,0,1.71-1.5,2.46-3.15,2.46A4.64,4.64,0,0,1,563.51,185Z" style={{fill: '#008ac4'}} />
            <path d="M575.92,178.49h2.2c.21,0,.29.15.15.32l-2.61,3,2.48,3.69c.12.17,0,.32-.17.32h-2.16a.35.35,0,0,1-.3-.17l-2.13-3.33v3.24a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26V175.1a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.6l2.22-3.06A.38.38,0,0,1,575.92,178.49Z" style={{fill: '#008ac4'}} />
            <path d="M579.1,176.2a1.35,1.35,0,1,1,1.35,1.35A1.33,1.33,0,0,1,579.1,176.2Zm2.25,9.6h-1.79a.23.23,0,0,1-.25-.26v-6.79a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v6.79A.23.23,0,0,1,581.35,185.8Z" style={{fill: '#008ac4'}} />
            <path d="M590.51,182.32a.24.24,0,0,1-.27.25h-5a1.62,1.62,0,0,0,1.65,1.38,2.46,2.46,0,0,0,1.78-.67.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.84,4.84,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.87,3.87,0,0,1,3.89-3.95A3.75,3.75,0,0,1,590.51,182.32Zm-5.2-.87h2.85a1.47,1.47,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- mazowieckie --> */}
        <a className="svg-voivodeship" href={this.link("mazowieckie")}>
          <polygon points="354.6 274.36 386.78 274.36 386.78 290.45 418.96 322.63 418.96 338.72 402.87 338.72 418.96 354.81 418.96 370.89 435.05 386.98 451.14 386.98 467.23 403.07 499.41 403.07 499.41 354.81 491.37 346.76 507.46 330.67 499.41 322.63 515.5 306.54 531.59 306.54 547.68 290.45 563.77 290.45 579.86 274.36 579.86 258.27 547.68 258.27 531.59 242.18 531.59 210 515.5 210 483.32 177.83 483.32 145.65 467.23 145.65 451.14 161.74 418.96 161.74 402.87 177.83 354.6 177.83 354.6 210 338.51 226.09 338.51 258.27 354.6 274.36" style={{fill: '#95d3e1'}} />
          <polygon points="354.6 274.36 386.78 274.36 386.78 290.45 418.96 322.63 418.96 338.72 402.87 338.72 418.96 354.81 418.96 370.89 435.05 386.98 451.14 386.98 467.23 403.07 499.41 403.07 499.41 354.81 491.37 346.76 507.46 330.67 499.41 322.63 515.5 306.54 531.59 306.54 547.68 290.45 563.77 290.45 579.86 274.36 579.86 258.27 547.68 258.27 531.59 242.18 531.59 210 515.5 210 483.32 177.83 483.32 145.65 467.23 145.65 451.14 161.74 418.96 161.74 402.87 177.83 354.6 177.83 354.6 210 338.51 226.09 338.51 258.27 354.6 274.36" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M427.3,265.64v4.46a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-4.2c0-.84-.39-1.2-1-1.2s-1.26.46-1.27,1.54v3.86a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-4.2c0-.84-.39-1.2-1.06-1.2s-1.14.39-1.23,1.29v4.11a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25V264a2.31,2.31,0,0,1,2.1-1.14,2.26,2.26,0,0,1,2.2,1.3,2.61,2.61,0,0,1,2.4-1.3C426.07,262.81,427.3,263.5,427.3,265.64Z" style={{fill: '#008ac4'}} />
            <path d="M434.2,263.3a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.8a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-.54a2.7,2.7,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.67,2.67,0,0,1,2.22,1.05Zm0,3.41a1.68,1.68,0,1,0-1.67,1.8A1.67,1.67,0,0,0,434.2,266.71Z" style={{fill: '#008ac4'}} />
            <path d="M444.13,265.06l-3,3.28h3.24a.23.23,0,0,1,.26.26v1.5a.23.23,0,0,1-.26.25h-6a.23.23,0,0,1-.26-.25v-1.35a.46.46,0,0,1,.12-.32l3.11-3.37h-3.17a.23.23,0,0,1-.25-.26v-1.5a.23.23,0,0,1,.25-.25H444a.23.23,0,0,1,.26.25v1.44A.46.46,0,0,1,444.13,265.06Z" style={{fill: '#008ac4'}} />
            <path d="M445.3,266.71a4,4,0,1,1,4,3.88A3.89,3.89,0,0,1,445.3,266.71Zm5.68,0a1.67,1.67,0,1,0-3.34,0,1.67,1.67,0,1,0,3.34,0Z" style={{fill: '#008ac4'}} />
            <path d="M464.3,263.26a.28.28,0,0,1,.3-.21h1.88a.21.21,0,0,1,.21.3l-2.55,6.79a.29.29,0,0,1-.29.21h-2a.27.27,0,0,1-.28-.19l-1.4-3.57-1.39,3.57a.28.28,0,0,1-.29.19h-2a.29.29,0,0,1-.29-.21l-2.53-6.79a.21.21,0,0,1,.21-.3h1.87a.28.28,0,0,1,.29.21l1.48,4.62,1.55-4.62a.28.28,0,0,1,.3-.21H461a.28.28,0,0,1,.3.21l1.54,4.56Z" style={{fill: '#008ac4'}} />
            <path d="M467.5,260.75a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,467.5,260.75Zm2.25,9.6H468a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.8A.23.23,0,0,1,469.75,270.35Z" style={{fill: '#008ac4'}} />
            <path d="M478.9,266.87a.25.25,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.45,2.45,0,0,0,1.79-.68.22.22,0,0,1,.36,0l1.06,1.2a.23.23,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.79,3.79,0,0,1-3.9-3.84,3.85,3.85,0,0,1,3.88-3.94A3.75,3.75,0,0,1,478.9,266.87Zm-5.21-.87h2.85a1.48,1.48,0,0,0-1.41-1.09A1.5,1.5,0,0,0,473.69,266Z" style={{fill: '#008ac4'}} />
            <path d="M479.62,266.69a3.79,3.79,0,0,1,3.91-3.88,3.84,3.84,0,0,1,2.91,1.21.24.24,0,0,1,0,.36l-1.14,1.19c-.12.13-.25.1-.36,0a1.64,1.64,0,0,0-1.36-.65,1.82,1.82,0,0,0,0,3.62,1.64,1.64,0,0,0,1.36-.65c.11-.13.24-.16.36,0l1.14,1.17a.24.24,0,0,1,0,.36,4,4,0,0,1-6.82-2.67Z" style={{fill: '#008ac4'}} />
            <path d="M492.38,263.05h2.21c.21,0,.28.15.15.31l-2.61,3L494.6,270c.12.16,0,.31-.16.31h-2.16a.34.34,0,0,1-.3-.16l-2.13-3.33v3.24a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25V259.66a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v6.6l2.22-3.06A.34.34,0,0,1,492.38,263.05Z" style={{fill: '#008ac4'}} />
            <path d="M495.56,260.75a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,495.56,260.75Zm2.25,9.6H496a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8A.23.23,0,0,1,497.81,270.35Z" style={{fill: '#008ac4'}} />
            <path d="M507,266.87a.25.25,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.45,2.45,0,0,0,1.79-.68.22.22,0,0,1,.36,0l1.06,1.2a.23.23,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.79,3.79,0,0,1-3.9-3.84,3.85,3.85,0,0,1,3.88-3.94A3.75,3.75,0,0,1,507,266.87Zm-5.21-.87h2.85a1.48,1.48,0,0,0-1.41-1.09A1.5,1.5,0,0,0,501.77,266Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- lubelskie --> */}
        <a className="svg-voivodeship" href={this.link("lubelskie")}>
          <polygon points="595.81 274.23 579.71 274.23 563.6 290.32 547.5 290.32 531.4 306.41 515.29 306.41 499.19 322.51 507.24 330.55 491.14 346.64 499.19 354.69 499.19 402.97 499.19 435.15 531.4 435.15 531.4 451.25 547.5 451.25 547.5 467.34 563.6 483.43 579.71 483.43 595.81 467.34 611.91 467.34 619.96 475.39 628.01 467.34 644.12 467.34 660.22 451.25 660.22 435.15 644.12 419.06 652.17 411.01 611.91 370.78 628.01 354.69 611.91 338.6 611.91 306.41 619.96 298.37 595.81 274.23" style={{fill: '#95d3e1'}} />
          <polygon points="595.81 274.23 579.71 274.23 563.6 290.32 547.5 290.32 531.4 306.41 515.29 306.41 499.19 322.51 507.24 330.55 491.14 346.64 499.19 354.69 499.19 402.97 499.19 435.15 531.4 435.15 531.4 451.25 547.5 451.25 547.5 467.34 563.6 483.43 579.71 483.43 595.81 467.34 611.91 467.34 619.96 475.39 628.01 467.34 644.12 467.34 660.22 451.25 660.22 435.15 644.12 419.06 652.17 411.01 611.91 370.78 628.01 354.69 611.91 338.6 611.91 306.41 619.96 298.37 595.81 274.23" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M536.57,395.53V385.09a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v10.44a.23.23,0,0,1-.26.25h-1.78A.23.23,0,0,1,536.57,395.53Z" style={{fill: '#008ac4'}} />
            <path d="M545.21,388.73a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-.71A2.45,2.45,0,0,1,543,396c-1.27,0-2.46-.69-2.46-2.82v-4.47a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v4c0,1,.36,1.41,1,1.41s1.32-.46,1.33-1.54Z" style={{fill: '#008ac4'}} />
            <path d="M557.2,392.14a3.68,3.68,0,0,1-3.45,3.88,2.69,2.69,0,0,1-2.22-1v.54a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25V385.09a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v4.2a2.65,2.65,0,0,1,2.22-1.05A3.69,3.69,0,0,1,557.2,392.14Zm-2.33,0a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,554.87,392.14Z" style={{fill: '#008ac4'}} />
            <path d="M565.64,392.3a.24.24,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.43,2.43,0,0,0,1.78-.68.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.86,3.86,0,0,1,3.89-3.94A3.74,3.74,0,0,1,565.64,392.3Zm-5.2-.87h2.85a1.48,1.48,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
            <path d="M566.83,395.53V385.09a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v10.44a.23.23,0,0,1-.25.25h-1.79A.23.23,0,0,1,566.83,395.53Z" style={{fill: '#008ac4'}} />
            <path d="M570.38,395a.25.25,0,0,1,0-.36l.82-1.17c.11-.15.23-.15.35,0a2.76,2.76,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23,1-2.32,2.85-2.32a4.56,4.56,0,0,1,3,.94.25.25,0,0,1,0,.36l-.82,1.07c-.11.15-.23.15-.35,0a2.5,2.5,0,0,0-1.78-.67c-.56,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44,0,1.71-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,570.38,395Z" style={{fill: '#008ac4'}} />
            <path d="M582.79,388.48H585c.21,0,.29.15.15.31l-2.61,3,2.48,3.69c.12.16,0,.31-.17.31h-2.16a.37.37,0,0,1-.3-.16l-2.13-3.33v3.24a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25V385.09a.23.23,0,0,1,.25-.26H580a.23.23,0,0,1,.25.26v6.6l2.22-3.06A.36.36,0,0,1,582.79,388.48Z" style={{fill: '#008ac4'}} />
            <path d="M586,386.18a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,586,386.18Zm2.25,9.6h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.8A.23.23,0,0,1,588.22,395.78Z" style={{fill: '#008ac4'}} />
            <path d="M597.38,392.3a.24.24,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.43,2.43,0,0,0,1.78-.68.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.86,3.86,0,0,1,3.89-3.94A3.74,3.74,0,0,1,597.38,392.3Zm-5.2-.87H595a1.48,1.48,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- podkarpackie --> */}
        <a className="svg-voivodeship" href={this.link("podkarpackie")}>
          <polygon points="612.04 499.33 563.72 547.63 563.72 579.82 579.83 595.91 579.83 612.01 547.62 612.01 515.41 579.82 483.19 579.82 483.19 563.72 467.09 547.62 467.09 483.24 499.3 451.05 499.3 434.95 531.51 434.95 531.51 451.05 547.62 451.05 547.62 467.14 563.72 483.24 579.83 483.24 595.93 467.14 612.04 467.14 620.09 475.19 612.04 483.24 612.04 499.33" style={{fill: '#95d3e1'}} />
          <polygon points="612.04 499.33 563.72 547.63 563.72 579.82 579.83 595.91 579.83 612.01 547.62 612.01 515.41 579.82 483.19 579.82 483.19 563.72 467.09 547.62 467.09 483.24 499.3 451.05 499.3 434.95 531.51 434.95 531.51 451.05 547.62 451.05 547.62 467.14 563.72 483.24 579.83 483.24 595.93 467.14 612.04 467.14 620.09 475.19 612.04 483.24 612.04 499.33" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M491.53,512.15a3.68,3.68,0,0,1-3.45,3.89,2.7,2.7,0,0,1-2.22-1v3.09a.23.23,0,0,1-.26.26h-1.78a.23.23,0,0,1-.26-.26v-9.34a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v.55a2.68,2.68,0,0,1,2.22-1.05A3.7,3.7,0,0,1,491.53,512.15Zm-2.33,0a1.67,1.67,0,1,0-1.66,1.8A1.68,1.68,0,0,0,489.2,512.15Z" style={{fill: '#008ac4'}} />
            <path d="M492.32,512.15a4,4,0,1,1,4,3.89A3.9,3.9,0,0,1,492.32,512.15Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M506.81,505.1a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v10.44a.23.23,0,0,1-.26.26h-1.78a.23.23,0,0,1-.26-.26V515a2.7,2.7,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.79,2.68,2.68,0,0,1,2.22,1.05Zm0,7a1.67,1.67,0,1,0-1.66,1.8A1.68,1.68,0,0,0,506.81,512.15Z" style={{fill: '#008ac4'}} />
            <path d="M515.65,508.49h2.2c.21,0,.29.15.15.32l-2.61,3,2.48,3.69c.12.17,0,.32-.17.32h-2.16a.35.35,0,0,1-.3-.17l-2.13-3.33v3.24a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26V505.1a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.6l2.22-3.06A.38.38,0,0,1,515.65,508.49Z" style={{fill: '#008ac4'}} />
            <path d="M523.94,508.75a.23.23,0,0,1,.26-.26H526a.23.23,0,0,1,.26.26v6.79a.23.23,0,0,1-.26.26H524.2a.23.23,0,0,1-.26-.26V515a2.7,2.7,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.79,2.68,2.68,0,0,1,2.22,1.05Zm0,3.4a1.67,1.67,0,1,0-1.66,1.8A1.68,1.68,0,0,0,523.94,512.15Z" style={{fill: '#008ac4'}} />
            <path d="M532.64,508.39a.23.23,0,0,1,.17.31l-.44,1.59c0,.18-.16.21-.31.12a1.38,1.38,0,0,0-.62-.15c-.67,0-1.11.5-1.11,1.44v3.84a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26v-6.79a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v.67a1.82,1.82,0,0,1,1.56-1.17A1.84,1.84,0,0,1,532.64,508.39Z" style={{fill: '#008ac4'}} />
            <path d="M541.58,512.15a3.68,3.68,0,0,1-3.45,3.89,2.71,2.71,0,0,1-2.22-1v3.09a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26v-9.34a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v.55a2.69,2.69,0,0,1,2.22-1.05A3.7,3.7,0,0,1,541.58,512.15Zm-2.32,0a1.68,1.68,0,1,0-1.67,1.8A1.68,1.68,0,0,0,539.26,512.15Z" style={{fill: '#008ac4'}} />
            <path d="M548.05,508.75a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v6.79a.23.23,0,0,1-.25.26H548.3a.23.23,0,0,1-.25-.26V515a2.71,2.71,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.79,2.69,2.69,0,0,1,2.22,1.05Zm0,3.4a1.68,1.68,0,1,0-1.67,1.8A1.68,1.68,0,0,0,548.05,512.15Z" style={{fill: '#008ac4'}} />
            <path d="M551.66,512.14a4,4,0,0,1,6.83-2.67.25.25,0,0,1,0,.36L557.35,511a.21.21,0,0,1-.36,0,1.81,1.81,0,1,0,0,2.33.21.21,0,0,1,.36,0l1.14,1.17a.25.25,0,0,1,0,.36,3.87,3.87,0,0,1-2.91,1.23A3.8,3.8,0,0,1,551.66,512.14Z" style={{fill: '#008ac4'}} />
            <path d="M564.43,508.49h2.2c.21,0,.29.15.15.32l-2.61,3,2.48,3.69c.12.17,0,.32-.17.32h-2.16a.35.35,0,0,1-.3-.17l-2.13-3.33v3.24a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26V505.1a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.6l2.22-3.06A.38.38,0,0,1,564.43,508.49Z" style={{fill: '#008ac4'}} />
            <path d="M567.61,506.2a1.35,1.35,0,1,1,1.35,1.35A1.33,1.33,0,0,1,567.61,506.2Zm2.25,9.6h-1.79a.23.23,0,0,1-.25-.26v-6.79a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v6.79A.23.23,0,0,1,569.86,515.8Z" style={{fill: '#008ac4'}} />
            <path d="M579,512.32a.24.24,0,0,1-.27.25h-5a1.62,1.62,0,0,0,1.65,1.38,2.46,2.46,0,0,0,1.78-.67.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.84,4.84,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.87,3.87,0,0,1,3.89-4A3.75,3.75,0,0,1,579,512.32Zm-5.2-.87h2.85a1.47,1.47,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- łódzkie --> */}
        <a className="svg-voivodeship" href={this.link("łódzkie")}>
          <polygon points="258.15 403.07 258.15 370.86 274.25 354.75 274.25 322.53 290.35 322.53 306.45 306.42 306.45 290.31 322.55 274.21 338.64 258.1 354.74 274.21 386.94 274.21 386.94 290.32 419.14 322.53 419.14 338.64 403.04 338.64 419.14 354.75 419.14 370.86 403.04 386.96 386.94 386.96 386.94 419.18 370.84 403.07 370.84 419.18 362.79 427.23 354.75 419.18 338.64 419.18 322.55 403.07 258.15 403.07" style={{fill: '#95d3e1'}} />
          <polygon points="258.15 403.07 258.15 370.86 274.25 354.75 274.25 322.53 290.35 322.53 306.45 306.42 306.45 290.31 322.55 274.21 338.64 258.1 354.74 274.21 386.94 274.21 386.94 290.32 419.14 322.53 419.14 338.64 403.04 338.64 419.14 354.75 419.14 370.86 403.04 386.96 386.94 386.96 386.94 419.18 370.84 403.07 370.84 419.18 362.79 427.23 354.75 419.18 338.64 419.18 322.55 403.07 258.15 403.07" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M321.11,346.33a.19.19,0,0,1,.3.19v1.29a.3.3,0,0,1-.19.3l-.87.38v5.43a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-4.43l-.81.36a.19.19,0,0,1-.3-.19v-1.29a.33.33,0,0,1,.2-.3l.91-.41v-4.18a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v3.18Z" style={{fill: '#008ac4'}} />
            <path d="M322,350.53a4,4,0,1,1,4,3.88A3.89,3.89,0,0,1,322,350.53Zm5.68,0a1.67,1.67,0,1,0-3.34,0,1.67,1.67,0,1,0,3.34,0ZM326.45,343h1.79c.21,0,.28.15.16.31l-1.66,2.28a.36.36,0,0,1-.32.15h-1.33c-.2,0-.27-.12-.18-.3l1.23-2.26A.33.33,0,0,1,326.45,343Z" style={{fill: '#008ac4'}} />
            <path d="M336.49,343.48a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v10.44a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-.54a2.7,2.7,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.67,2.67,0,0,1,2.22,1.05Zm0,7a1.68,1.68,0,1,0-1.67,1.8A1.67,1.67,0,0,0,336.49,350.53Z" style={{fill: '#008ac4'}} />
            <path d="M346.37,348.88l-3,3.28h3.24a.23.23,0,0,1,.25.26v1.5a.23.23,0,0,1-.25.25h-6a.23.23,0,0,1-.25-.25v-1.35a.46.46,0,0,1,.12-.32l3.1-3.37h-3.16a.23.23,0,0,1-.26-.26v-1.5a.23.23,0,0,1,.26-.25h5.82a.23.23,0,0,1,.25.25v1.44A.46.46,0,0,1,346.37,348.88Z" style={{fill: '#008ac4'}} />
            <path d="M353,346.87h2.21c.21,0,.28.15.15.31l-2.61,3,2.47,3.69c.12.16,0,.31-.16.31H352.9a.34.34,0,0,1-.3-.16l-2.13-3.33v3.24a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25V343.48a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v6.6l2.22-3.06A.34.34,0,0,1,353,346.87Z" style={{fill: '#008ac4'}} />
            <path d="M356.18,344.57a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,356.18,344.57Zm2.25,9.6h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8A.23.23,0,0,1,358.43,354.17Z" style={{fill: '#008ac4'}} />
            <path d="M367.6,350.69a.25.25,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.45,2.45,0,0,0,1.79-.68.22.22,0,0,1,.36,0l1.06,1.2a.23.23,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.79,3.79,0,0,1-3.9-3.84,3.85,3.85,0,0,1,3.88-3.94A3.75,3.75,0,0,1,367.6,350.69Zm-5.21-.87h2.85a1.48,1.48,0,0,0-1.41-1.09A1.5,1.5,0,0,0,362.39,349.82Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- świętokrzyskie --> */}
        <a className="svg-voivodeship" href={this.link("świętokrzyskie")}>
          <polygon points="370.62 435.05 362.56 427 370.62 418.94 370.62 402.83 386.73 418.94 386.73 386.72 402.84 386.72 418.95 370.6 435.06 386.72 451.17 386.72 467.28 402.83 499.5 402.83 499.5 435.06 499.5 451.17 467.28 483.39 451.17 483.39 435.06 499.5 418.95 499.5 402.84 483.39 402.84 467.28 386.73 467.28 370.62 451.17 370.62 435.05" style={{fill: '#95d3e1'}} />
          <polygon points="370.62 435.05 362.56 427 370.62 418.94 370.62 402.83 386.73 418.94 386.73 386.72 402.84 386.72 418.95 370.6 435.06 386.72 451.17 386.72 467.28 402.83 499.5 402.83 499.5 435.06 499.5 451.17 467.28 483.39 451.17 483.39 435.06 499.5 418.95 499.5 402.84 483.39 402.84 467.28 386.73 467.28 370.62 451.17 370.62 435.05" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M387,439.54a.25.25,0,0,1-.05-.36l.83-1.17c.1-.15.22-.15.34,0a2.72,2.72,0,0,0,2.07.84c.65,0,1.13-.18,1.13-.63s-.54-.55-1.35-.67c-1.56-.21-2.85-.77-2.85-2.43,0-1.23.94-2.33,2.85-2.33a4.59,4.59,0,0,1,3,1,.25.25,0,0,1,0,.36l-.83,1.06c-.1.15-.22.15-.34,0a2.54,2.54,0,0,0-1.79-.68c-.55,0-1,.14-1,.53s.3.52,1.32.64c1.38.2,2.88.59,2.88,2.45,0,1.71-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,387,439.54Zm3.63-10.43h1.78c.21,0,.29.15.17.32l-1.67,2.28a.36.36,0,0,1-.31.15h-1.34c-.19,0-.27-.12-.18-.3l1.23-2.27A.34.34,0,0,1,390.64,429.11Z" style={{fill: '#008ac4'}} />
            <path d="M404.65,433.22a.28.28,0,0,1,.3-.21h1.87a.21.21,0,0,1,.21.3l-2.55,6.8a.29.29,0,0,1-.28.21h-2a.28.28,0,0,1-.29-.2l-1.39-3.57-1.4,3.57a.27.27,0,0,1-.28.2h-2a.29.29,0,0,1-.28-.21l-2.54-6.8a.21.21,0,0,1,.21-.3H396a.27.27,0,0,1,.28.21l1.49,4.62,1.54-4.62a.28.28,0,0,1,.3-.21h1.68a.28.28,0,0,1,.3.21l1.55,4.56Z" style={{fill: '#008ac4'}} />
            <path d="M407.84,430.72a1.35,1.35,0,1,1,1.35,1.35A1.33,1.33,0,0,1,407.84,430.72Zm2.25,9.6h-1.78a.23.23,0,0,1-.26-.26v-6.79a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26v6.79A.23.23,0,0,1,410.09,440.32Z" style={{fill: '#008ac4'}} />
            <path d="M419.36,436.84a.24.24,0,0,1-.27.25h-5a1.62,1.62,0,0,0,1.65,1.38,2.46,2.46,0,0,0,1.78-.67.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.46,4.46,0,0,1-1.74,1c-.61.32-1.18.8-1.18,1.26,0,.26.15.39.42.39a1.39,1.39,0,0,0,.75-.24c.16-.09.28-.06.34.11l.27.7a.23.23,0,0,1-.1.33,2.86,2.86,0,0,1-1.49.39c-.93,0-1.66-.48-1.66-1.42a1.86,1.86,0,0,1,.63-1.32,3.76,3.76,0,0,1-3.44-3.81,3.87,3.87,0,0,1,3.89-4A3.75,3.75,0,0,1,419.36,436.84Zm-5.2-.87H417a1.47,1.47,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
            <path d="M425.77,438.17l.55,1.38a.23.23,0,0,1-.1.33,4.34,4.34,0,0,1-2.46.68c-1.77,0-2.75-1-2.75-3V435h-.79a.23.23,0,0,1-.26-.25v-1.5a.23.23,0,0,1,.26-.26H421v-2a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v2h2a.23.23,0,0,1,.26.26v1.5a.23.23,0,0,1-.26.25h-2v2.27c0,.72.18,1.2,1,1.2a1.84,1.84,0,0,0,1.15-.41A.2.2,0,0,1,425.77,438.17Z" style={{fill: '#008ac4'}} />
            <path d="M426.59,436.67a4,4,0,1,1,4,3.89A3.9,3.9,0,0,1,426.59,436.67Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M440.68,433h2.2c.21,0,.29.15.15.32l-2.61,3L442.9,440c.12.17,0,.32-.17.32h-2.16a.35.35,0,0,1-.3-.17l-2.13-3.33v3.24a.23.23,0,0,1-.25.26H436.1a.23.23,0,0,1-.25-.26V429.62a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.6l2.22-3.06A.38.38,0,0,1,440.68,433Z" style={{fill: '#008ac4'}} />
            <path d="M448.67,432.91a.23.23,0,0,1,.17.31l-.44,1.59c0,.18-.16.21-.31.12a1.38,1.38,0,0,0-.62-.15c-.67,0-1.11.5-1.11,1.44v3.84a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26v-6.79a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v.67a1.82,1.82,0,0,1,1.56-1.17A1.84,1.84,0,0,1,448.67,432.91Z" style={{fill: '#008ac4'}} />
            <path d="M455.66,435l-3,3.29h3.24a.23.23,0,0,1,.25.25v1.5a.23.23,0,0,1-.25.26h-6a.23.23,0,0,1-.25-.26v-1.35a.42.42,0,0,1,.12-.31l3.1-3.38h-3.16a.23.23,0,0,1-.26-.25v-1.5a.23.23,0,0,1,.26-.26h5.82a.23.23,0,0,1,.25.26v1.44A.42.42,0,0,1,455.66,435Z" style={{fill: '#008ac4'}} />
            <path d="M462.19,433.22a.29.29,0,0,1,.28-.21h1.91a.21.21,0,0,1,.21.3L461,442.67a.27.27,0,0,1-.28.2H458.9a.21.21,0,0,1-.21-.3l.77-2-2.76-7.23a.21.21,0,0,1,.21-.3h1.9a.28.28,0,0,1,.3.21l1.5,4.23Z" style={{fill: '#008ac4'}} />
            <path d="M465,439.54a.25.25,0,0,1,0-.36l.82-1.17a.2.2,0,0,1,.35,0,2.72,2.72,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.55-1.35-.67c-1.56-.21-2.85-.77-2.85-2.43,0-1.23.95-2.33,2.85-2.33a4.57,4.57,0,0,1,3,1,.25.25,0,0,1,0,.36l-.82,1.06a.2.2,0,0,1-.35,0,2.51,2.51,0,0,0-1.78-.68c-.56,0-1,.14-1,.53s.3.52,1.32.64c1.38.2,2.88.59,2.88,2.45,0,1.71-1.5,2.46-3.15,2.46A4.64,4.64,0,0,1,465,439.54Z" style={{fill: '#008ac4'}} />
            <path d="M477.4,433h2.2c.21,0,.29.15.15.32l-2.61,3,2.48,3.69c.12.17,0,.32-.17.32h-2.16a.35.35,0,0,1-.3-.17l-2.13-3.33v3.24a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26V429.62a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.6l2.22-3.06A.38.38,0,0,1,477.4,433Z" style={{fill: '#008ac4'}} />
            <path d="M480.58,430.72a1.35,1.35,0,1,1,1.35,1.35A1.33,1.33,0,0,1,480.58,430.72Zm2.25,9.6H481a.23.23,0,0,1-.25-.26v-6.79A.23.23,0,0,1,481,433h1.79a.23.23,0,0,1,.25.26v6.79A.23.23,0,0,1,482.83,440.32Z" style={{fill: '#008ac4'}} />
            <path d="M492,436.84a.24.24,0,0,1-.27.25h-5a1.62,1.62,0,0,0,1.65,1.38,2.46,2.46,0,0,0,1.78-.67.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.84,4.84,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.87,3.87,0,0,1,3.89-4A3.75,3.75,0,0,1,492,436.84Zm-5.2-.87h2.85a1.47,1.47,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- małopolskie --> */}
        <a className="svg-voivodeship" href={this.link("małopolskie")}>
          <polygon points="322.38 515.41 338.49 531.51 338.49 547.62 354.59 563.73 354.59 579.83 370.7 579.83 370.7 595.94 386.8 595.94 402.91 579.83 435.12 579.83 443.17 587.89 451.22 579.83 483.43 579.83 483.43 563.73 467.32 547.62 467.32 483.2 451.22 483.2 435.12 499.3 419.01 499.3 402.91 483.2 402.91 467.09 386.8 467.09 354.59 467.09 354.59 483.2 322.38 515.41" style={{fill: '#95d3e1'}} />
          <polygon points="322.38 515.41 338.49 531.51 338.49 547.62 354.59 563.73 354.59 579.83 370.7 579.83 370.7 595.94 386.8 595.94 402.91 579.83 435.12 579.83 443.17 587.89 451.22 579.83 483.43 579.83 483.43 563.73 467.32 547.62 467.32 483.2 451.22 483.2 435.12 499.3 419.01 499.3 402.91 483.2 402.91 467.09 386.8 467.09 354.59 467.09 354.59 483.2 322.38 515.41" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M373,526.55V531a.23.23,0,0,1-.26.25H371a.23.23,0,0,1-.26-.25v-4.2c0-.84-.39-1.2-1-1.2s-1.26.46-1.27,1.54V531a.23.23,0,0,1-.26.25H366.4a.23.23,0,0,1-.26-.25v-4.2c0-.84-.39-1.2-1.06-1.2s-1.14.39-1.23,1.29V531a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v.65a2.31,2.31,0,0,1,2.1-1.14,2.26,2.26,0,0,1,2.2,1.3,2.61,2.61,0,0,1,2.4-1.3C371.8,523.72,373,524.41,373,526.55Z" style={{fill: '#008ac4'}} />
            <path d="M379.93,524.21a.23.23,0,0,1,.25-.25H382a.23.23,0,0,1,.25.25V531a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-.54a2.7,2.7,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.67,2.67,0,0,1,2.22,1Zm0,3.41a1.68,1.68,0,1,0-1.67,1.8A1.67,1.67,0,0,0,379.93,527.62Z" style={{fill: '#008ac4'}} />
            <path d="M387.7,523.42a.19.19,0,0,1,.3.19v1.29a.3.3,0,0,1-.2.3l-.87.38V531a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-4.43l-.81.36a.19.19,0,0,1-.3-.19v-1.29a.32.32,0,0,1,.19-.3l.92-.41v-4.18a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v3.18Z" style={{fill: '#008ac4'}} />
            <path d="M388.58,527.62a4,4,0,1,1,4,3.88A3.9,3.9,0,0,1,388.58,527.62Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M405.8,527.62a3.68,3.68,0,0,1-3.45,3.88,2.7,2.7,0,0,1-2.22-1v3.09a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-9.35a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v.56a2.67,2.67,0,0,1,2.22-1A3.69,3.69,0,0,1,405.8,527.62Zm-2.32,0a1.68,1.68,0,1,0-1.67,1.8A1.67,1.67,0,0,0,403.48,527.62Z" style={{fill: '#008ac4'}} />
            <path d="M406.6,527.62a4,4,0,1,1,4,3.88A3.89,3.89,0,0,1,406.6,527.62Zm5.68,0a1.67,1.67,0,1,0-3.34,0,1.67,1.67,0,1,0,3.34,0Z" style={{fill: '#008ac4'}} />
            <path d="M415.87,531V520.57a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26V531a.23.23,0,0,1-.25.25h-1.79A.23.23,0,0,1,415.87,531Z" style={{fill: '#008ac4'}} />
            <path d="M419.42,530.48a.25.25,0,0,1,0-.36l.82-1.17c.11-.15.23-.15.35,0a2.76,2.76,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23.95-2.32,2.85-2.32a4.56,4.56,0,0,1,3,.94.25.25,0,0,1,0,.36l-.82,1.07c-.11.15-.23.15-.35,0a2.5,2.5,0,0,0-1.78-.67c-.56,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44s-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,419.42,530.48Z" style={{fill: '#008ac4'}} />
            <path d="M431.83,524H434c.21,0,.29.15.15.31l-2.61,3,2.48,3.69c.12.16,0,.31-.17.31h-2.16a.37.37,0,0,1-.3-.16l-2.13-3.33V531a.23.23,0,0,1-.25.25h-1.79A.23.23,0,0,1,427,531V520.57a.23.23,0,0,1,.25-.26H429a.23.23,0,0,1,.25.26v6.6l2.22-3.06A.36.36,0,0,1,431.83,524Z" style={{fill: '#008ac4'}} />
            <path d="M435,521.66a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,435,521.66Zm2.25,9.6h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25V531A.23.23,0,0,1,437.26,531.26Z" style={{fill: '#008ac4'}} />
            <path d="M446.42,527.78a.24.24,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.43,2.43,0,0,0,1.78-.68.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.86,3.86,0,0,1,3.89-3.94A3.74,3.74,0,0,1,446.42,527.78Zm-5.2-.87h2.85a1.48,1.48,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- śląskie --> */}
        <a className="svg-voivodeship" href={this.link("śląskie")}>
          <polygon points="346.85 555.77 338.79 547.72 338.79 531.62 322.65 515.52 354.92 483.31 354.92 467.21 387.18 467.21 371.05 451.11 371.05 435.01 362.98 426.96 354.92 418.91 338.79 418.91 322.65 402.8 290.39 402.8 290.39 435.01 282.32 443.06 290.39 451.11 274.26 467.21 274.26 483.31 250.06 507.46 274.26 531.62 290.39 531.62 290.39 547.72 306.52 547.72 306.52 563.82 322.65 579.92 346.85 555.77" style={{fill: '#95d3e1'}} />
          <polygon points="346.85 555.77 338.79 547.72 338.79 531.62 322.65 515.52 354.92 483.31 354.92 467.21 387.18 467.21 371.05 451.11 371.05 435.01 362.98 426.96 354.92 418.91 338.79 418.91 322.65 402.8 290.39 402.8 290.39 435.01 282.32 443.06 290.39 451.11 274.26 467.21 274.26 483.31 250.06 507.46 274.26 531.62 290.39 531.62 290.39 547.72 306.52 547.72 306.52 563.82 322.65 579.92 346.85 555.77" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M287.72,481.19a.25.25,0,0,1,0-.36l.82-1.17c.11-.15.23-.15.35,0a2.76,2.76,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23,1-2.32,2.85-2.32a4.56,4.56,0,0,1,3,.94.25.25,0,0,1,0,.36l-.82,1.07c-.11.15-.23.15-.35,0a2.5,2.5,0,0,0-1.78-.67c-.56,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44,0,1.71-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,287.72,481.19Zm3.63-10.42h1.79c.21,0,.28.15.16.31l-1.66,2.28a.36.36,0,0,1-.32.15H290c-.2,0-.27-.12-.18-.3L291,471A.33.33,0,0,1,291.35,470.77Z" style={{fill: '#008ac4'}} />
            <path d="M295.39,481.72V471.28a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v10.44a.23.23,0,0,1-.25.25h-1.79A.23.23,0,0,1,295.39,481.72Z" style={{fill: '#008ac4'}} />
            <path d="M307,483.65l.27.71a.23.23,0,0,1-.11.33,2.8,2.8,0,0,1-1.48.39c-.93,0-1.67-.48-1.67-1.43a2.21,2.21,0,0,1,1.07-1.68H305a.23.23,0,0,1-.26-.25v-.54a2.69,2.69,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.65,2.65,0,0,1,2.22,1.05v-.56a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.84a.3.3,0,0,1-.2.3c-.66.32-1.35.83-1.35,1.34a.37.37,0,0,0,.42.39,1.48,1.48,0,0,0,.75-.24C306.79,483.46,306.91,483.49,307,483.65Zm-2.27-5.32a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,304.7,478.33Z" style={{fill: '#008ac4'}} />
            <path d="M308.41,481.19a.25.25,0,0,1-.05-.36l.83-1.17c.1-.15.22-.15.34,0a2.76,2.76,0,0,0,2.07.84c.65,0,1.13-.18,1.13-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23.94-2.32,2.85-2.32a4.58,4.58,0,0,1,3,.94.25.25,0,0,1,0,.36l-.83,1.07c-.1.15-.22.15-.34,0a2.53,2.53,0,0,0-1.79-.67c-.55,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44,0,1.71-1.5,2.46-3.15,2.46A4.54,4.54,0,0,1,308.41,481.19Z" style={{fill: '#008ac4'}} />
            <path d="M320.81,474.67H323c.21,0,.28.15.15.31l-2.61,3,2.47,3.69c.12.16.05.31-.16.31h-2.16a.34.34,0,0,1-.3-.16l-2.13-3.33v3.24a.23.23,0,0,1-.26.25h-1.78a.23.23,0,0,1-.26-.25V471.28a.23.23,0,0,1,.26-.26H318a.23.23,0,0,1,.26.26v6.6l2.22-3.06A.34.34,0,0,1,320.81,474.67Z" style={{fill: '#008ac4'}} />
            <path d="M324,472.37a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,324,472.37Zm2.25,9.6h-1.78a.23.23,0,0,1-.26-.25v-6.8a.23.23,0,0,1,.26-.25h1.78a.23.23,0,0,1,.26.25v6.8A.23.23,0,0,1,326.24,482Z" style={{fill: '#008ac4'}} />
            <path d="M335.41,478.49a.25.25,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.45,2.45,0,0,0,1.79-.68.22.22,0,0,1,.36,0l1.06,1.2a.23.23,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.79,3.79,0,0,1-3.9-3.84,3.85,3.85,0,0,1,3.88-3.94A3.75,3.75,0,0,1,335.41,478.49Zm-5.21-.87h2.85a1.48,1.48,0,0,0-1.41-1.09A1.5,1.5,0,0,0,330.2,477.62Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- opolskie --> */}
        <a className="svg-voivodeship" href={this.link("opolskie")}>
          <polygon points="226.12 386.54 242.24 402.68 290.62 402.68 290.62 434.95 282.56 443.02 290.62 451.09 274.49 467.22 274.49 483.36 242.24 515.63 226.12 499.5 234.18 491.43 226.12 483.36 193.86 483.36 177.74 467.22 193.86 451.09 193.86 434.95 226.12 402.68 226.12 386.54" style={{fill: '#95d3e1'}} />
          <polygon points="226.12 386.54 242.24 402.68 290.62 402.68 290.62 434.95 282.56 443.02 290.62 451.09 274.49 467.22 274.49 483.36 242.24 515.63 226.12 499.5 234.18 491.43 226.12 483.36 193.86 483.36 177.74 467.22 193.86 451.09 193.86 434.95 226.12 402.68 226.12 386.54" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M209.27,447.38a4,4,0,1,1,4,3.89A3.9,3.9,0,0,1,209.27,447.38Zm5.69,0a1.68,1.68,0,1,0-3.35,0,1.68,1.68,0,1,0,3.35,0Z" style={{fill: '#008ac4'}} />
            <path d="M226.49,447.38a3.68,3.68,0,0,1-3.45,3.89,2.71,2.71,0,0,1-2.22-1v3.09a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26V444a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26v.55a2.69,2.69,0,0,1,2.22-1A3.7,3.7,0,0,1,226.49,447.38Zm-2.32,0a1.68,1.68,0,1,0-1.67,1.8A1.68,1.68,0,0,0,224.17,447.38Z" style={{fill: '#008ac4'}} />
            <path d="M227.29,447.38a4,4,0,1,1,4,3.89A3.89,3.89,0,0,1,227.29,447.38Zm5.68,0a1.67,1.67,0,1,0-1.66,1.8A1.67,1.67,0,0,0,233,447.38Z" style={{fill: '#008ac4'}} />
            <path d="M236.56,450.77V440.33a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v10.44a.23.23,0,0,1-.25.26h-1.79A.23.23,0,0,1,236.56,450.77Z" style={{fill: '#008ac4'}} />
            <path d="M240.11,450.25a.25.25,0,0,1,0-.36l.82-1.17a.2.2,0,0,1,.35,0,2.72,2.72,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.55-1.35-.67c-1.56-.21-2.85-.77-2.85-2.43,0-1.23,1-2.33,2.85-2.33a4.57,4.57,0,0,1,3,.95.25.25,0,0,1,0,.36l-.82,1.06a.2.2,0,0,1-.35,0,2.51,2.51,0,0,0-1.78-.68c-.56,0-1,.14-1,.53s.3.52,1.32.64c1.38.2,2.88.59,2.88,2.45,0,1.71-1.5,2.46-3.15,2.46A4.64,4.64,0,0,1,240.11,450.25Z" style={{fill: '#008ac4'}} />
            <path d="M252.52,443.72h2.2c.21,0,.29.15.15.32l-2.61,3,2.48,3.69c.12.17,0,.32-.17.32h-2.16a.35.35,0,0,1-.3-.17L250,447.53v3.24a.23.23,0,0,1-.25.26h-1.79a.23.23,0,0,1-.25-.26V440.33a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v6.6l2.22-3.06A.38.38,0,0,1,252.52,443.72Z" style={{fill: '#008ac4'}} />
            <path d="M255.7,441.43a1.35,1.35,0,1,1,1.35,1.35A1.33,1.33,0,0,1,255.7,441.43ZM258,451h-1.79a.23.23,0,0,1-.25-.26V444a.23.23,0,0,1,.25-.26H258a.23.23,0,0,1,.25.26v6.79A.23.23,0,0,1,258,451Z" style={{fill: '#008ac4'}} />
            <path d="M267.11,447.55a.24.24,0,0,1-.27.25h-5a1.62,1.62,0,0,0,1.65,1.38,2.46,2.46,0,0,0,1.78-.67.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.84,4.84,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.87,3.87,0,0,1,3.89-3.95A3.75,3.75,0,0,1,267.11,447.55Zm-5.2-.87h2.85a1.47,1.47,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
          </g>
        </a>

        {/* <!-- dolnośląskie --> */}
        <a className="svg-voivodeship" href={this.link("dolnośląskie")}>
          <polygon points="169.98 475.4 178.03 467.35 194.13 451.24 194.13 435.13 226.33 402.92 226.33 370.71 218.28 362.65 226.33 354.6 210.23 338.49 194.13 354.6 178.03 354.6 145.82 322.39 137.77 330.44 129.72 322.39 97.52 354.6 89.47 346.55 73.37 362.65 65.32 354.6 49.22 370.71 49.22 402.92 65.32 402.92 65.32 419.03 81.42 435.13 97.52 435.13 113.62 451.24 129.72 435.13 145.82 451.24 129.72 467.35 161.93 499.56 178.03 483.45 169.98 475.4" style={{fill: '#95d3e1'}} />
          <polygon points="169.98 475.4 178.03 467.35 194.13 451.24 194.13 435.13 226.33 402.92 226.33 370.71 218.28 362.65 226.33 354.6 210.23 338.49 194.13 354.6 178.03 354.6 145.82 322.39 137.77 330.44 129.72 322.39 97.52 354.6 89.47 346.55 73.37 362.65 65.32 354.6 49.22 370.71 49.22 402.92 65.32 402.92 65.32 419.03 81.42 435.13 97.52 435.13 113.62 451.24 129.72 435.13 145.82 451.24 129.72 467.35 161.93 499.56 178.03 483.45 169.98 475.4" style={{fill: 'none', stroke: '#fff', strokeLinecap: 'square', strokeLinejoin: 'bevel', strokeWidth: '2px'}} />
          <g>
            <path d="M102.73,390.55a.23.23,0,0,1,.25-.26h1.79a.23.23,0,0,1,.25.26V401a.23.23,0,0,1-.25.25H103a.23.23,0,0,1-.25-.25v-.54a2.7,2.7,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.67,2.67,0,0,1,2.22,1.05Zm0,7.05a1.68,1.68,0,1,0-1.67,1.8A1.67,1.67,0,0,0,102.73,397.6Z" style={{fill: '#008ac4'}} />
            <path d="M106.27,397.6a4,4,0,1,1,4,3.88A3.89,3.89,0,0,1,106.27,397.6Zm5.68,0a1.67,1.67,0,1,0-3.34,0,1.67,1.67,0,1,0,3.34,0Z" style={{fill: '#008ac4'}} />
            <path d="M115.52,401V390.55a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26V401a.23.23,0,0,1-.26.25h-1.78A.23.23,0,0,1,115.52,401Z" style={{fill: '#008ac4'}} />
            <path d="M126.64,396.53V401a.23.23,0,0,1-.26.25H124.6a.23.23,0,0,1-.26-.25v-4c0-1.05-.39-1.41-1.12-1.41a1.32,1.32,0,0,0-1.37,1.3V401a.23.23,0,0,1-.25.25h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25v.71a2.51,2.51,0,0,1,2.27-1.2C125.41,393.7,126.64,394.39,126.64,396.53Z" style={{fill: '#008ac4'}} />
            <path d="M127.84,397.6a4,4,0,1,1,4,3.88A3.89,3.89,0,0,1,127.84,397.6Zm5.68,0a1.67,1.67,0,1,0-3.34,0,1.67,1.67,0,1,0,3.34,0Z" style={{fill: '#008ac4'}} />
            <path d="M136.67,400.46a.25.25,0,0,1,0-.36l.82-1.17c.11-.15.23-.15.35,0a2.76,2.76,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23,1-2.32,2.85-2.32a4.56,4.56,0,0,1,3,.94.25.25,0,0,1,0,.36l-.82,1.07c-.11.15-.23.15-.35,0a2.5,2.5,0,0,0-1.78-.67c-.56,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44s-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,136.67,400.46ZM140.3,390h1.79c.21,0,.28.15.16.31l-1.66,2.28a.36.36,0,0,1-.32.15h-1.33c-.2,0-.27-.12-.18-.3l1.23-2.26A.33.33,0,0,1,140.3,390Z" style={{fill: '#008ac4'}} />
            <path d="M144.35,401V390.55a.23.23,0,0,1,.26-.26h1.78a.23.23,0,0,1,.26.26V401a.23.23,0,0,1-.26.25h-1.78A.23.23,0,0,1,144.35,401Z" style={{fill: '#008ac4'}} />
            <path d="M155.93,402.92l.27.71a.24.24,0,0,1-.1.33,2.86,2.86,0,0,1-1.49.39c-.93,0-1.66-.48-1.66-1.43a2.2,2.2,0,0,1,1.06-1.68h-.09a.23.23,0,0,1-.25-.25v-.54a2.7,2.7,0,0,1-2.22,1,3.92,3.92,0,0,1,0-7.78,2.67,2.67,0,0,1,2.22,1.05v-.56a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25V401a.3.3,0,0,1-.19.3c-.66.32-1.35.83-1.35,1.34a.37.37,0,0,0,.42.39,1.48,1.48,0,0,0,.75-.24C155.75,402.73,155.87,402.76,155.93,402.92Zm-2.26-5.32a1.68,1.68,0,1,0-1.67,1.8A1.67,1.67,0,0,0,153.67,397.6Z" style={{fill: '#008ac4'}} />
            <path d="M157.37,400.46a.25.25,0,0,1,0-.36l.82-1.17c.11-.15.23-.15.35,0a2.76,2.76,0,0,0,2.07.84c.64,0,1.12-.18,1.12-.63s-.54-.56-1.35-.68c-1.56-.21-2.85-.76-2.85-2.43,0-1.23.95-2.32,2.85-2.32a4.56,4.56,0,0,1,3,.94.25.25,0,0,1,0,.36l-.82,1.07c-.11.15-.23.15-.35,0a2.5,2.5,0,0,0-1.78-.67c-.56,0-1,.13-1,.52s.3.53,1.32.65c1.38.19,2.88.58,2.88,2.44s-1.5,2.46-3.15,2.46A4.59,4.59,0,0,1,157.37,400.46Z" style={{fill: '#008ac4'}} />
            <path d="M169.78,393.94H172c.21,0,.29.15.15.31l-2.61,3,2.48,3.69c.12.16,0,.31-.17.31h-2.16a.37.37,0,0,1-.3-.16l-2.13-3.33V401a.23.23,0,0,1-.25.25H165.2A.23.23,0,0,1,165,401V390.55a.23.23,0,0,1,.25-.26H167a.23.23,0,0,1,.25.26v6.6l2.22-3.06A.36.36,0,0,1,169.78,393.94Z" style={{fill: '#008ac4'}} />
            <path d="M173,391.64a1.35,1.35,0,1,1,1.35,1.35A1.32,1.32,0,0,1,173,391.64Zm2.25,9.6h-1.79a.23.23,0,0,1-.25-.25v-6.8a.23.23,0,0,1,.25-.25h1.79a.23.23,0,0,1,.25.25V401A.23.23,0,0,1,175.21,401.24Z" style={{fill: '#008ac4'}} />
            <path d="M184.37,397.76a.24.24,0,0,1-.27.26h-5a1.63,1.63,0,0,0,1.65,1.38,2.43,2.43,0,0,0,1.78-.68.22.22,0,0,1,.36,0l1.07,1.2a.24.24,0,0,1,0,.36,4.79,4.79,0,0,1-3.3,1.2,3.8,3.8,0,0,1-3.9-3.84,3.86,3.86,0,0,1,3.89-3.94A3.74,3.74,0,0,1,184.37,397.76Zm-5.2-.87H182a1.48,1.48,0,0,0-2.85,0Z" style={{fill: '#008ac4'}} />
          </g>
        </a>
      </svg>
    )
  }
}

SearchMap.defaultProps = {
  categories: []
}

SearchMap.propTypes = {
  categories: PropTypes.array
}

window.renderSearchMap = (el) => {
  ReactDOM.render(
    <SearchMap />,
    el
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('search-map')

  if (el) {
    window.renderSearchMap(el)
  }
})
